.browse_card {
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;
  margin: 20px;
  overflow: hidden;
}

.browse_card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.browse_title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #343a40;
}

.browse_card-body {
  padding: 20px;
}

.browse_search-bar {
  display: flex;
  align-items: center;
}

.browse_search-bar input {
  flex: 1;
  margin-right: 10px;
  width: auto;
}

.browse_header-right {
  display: flex;
  align-items: center;
}

.browse_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.browse_table thead {
  background-color: #f8f9fa;
  color: #343a40;
}

.browse_table th,
.browse_table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.browse_table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.browse_table .text-right {
  text-align: right;
}

.browse_btn {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px; /* Cambiado para que coincida con los botones del formulario */
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.browse_btn .fas {
  margin-right: 5px;
}

.browse_btn-primary {
  background-color: #007bff;
  color: white;
}

.browse_btn-outline-secondary {
  background-color: transparent;
  color: #6c757d;
  border: 1px solid #6c757d;
}

.browse_btn-outline-primary {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.browse_btn-outline-danger {
  background-color: transparent;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.browse_btn-new {
  background-color: #007bff;
  color: white;
  border-radius: 5px; /* Cambiado para que coincida con los botones del formulario */
  padding: 10px 20px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.browse_btn-close {
  background: none;
  color: #6c757d;
  border: none;
  font-size: 1.5rem;
}

.browse_card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.browse_pagination-controls {
  display: flex;
  align-items: center;
}

.browse_page-info {
  margin: 0 10px;
}

.browse_table tbody tr:hover {
  background-color: #e9ecef; /* Color de fondo al pasar el mouse */
}
