/* Estilos del modal */
/* .form_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow: hidden;
}

.form_modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
} */
.form_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  max-height: 70%;
  min-height: 40%;
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow: auto;
}

.form_modal_cruce {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 900px;
  max-height: 70%;
  min-height: 40%;
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow: auto;
}

.form_modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
}

/* Estilos del contenido del modal */
.form_card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  color: #343a40;
}

.form_btn-close {
  background: none;
  color: #6c757d;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.form_card-body {
  padding: 20px;
}

.form_group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form_group label {
  font-weight: bold;
  margin-right: 10px;
  width: 150px;
}

.form_group input {
  flex: 1;
}

/* Estilos adicionales para los campos de solo lectura */
.read-only-label {
  color: #6c757d; /* Color más claro para indicar que está en modo lectura */
}

.read-only-input {
  background-color: #e9ecef; /* Fondo gris claro para indicar que está en modo lectura */
  cursor: not-allowed; /* Cursor no permitido */
}

.form_btn {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.form_btn .fas {
  margin-right: 5px;
}

.form_btn-primary {
  background-color: #007bff;
  color: white;
}
